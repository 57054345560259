// material-ui
import { Backdrop, Button, CircularProgress, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, Snackbar, Step, StepButton, StepLabel, Stepper, TextField, Typography, useMediaQuery } from '@mui/material';
import { Formik, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

// project imports
import * as Yup from 'yup';
import React from 'react';
import MuiAlert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import InfoPerso from './InfoPerso';
import RenseigAgent from './RenseigAgent';
import Cv from './Cv';
import InfoHandicap from './InfoHandicap';
import { Fragment } from 'react';
import { Save } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { fonctActions } from '../../sagas/fonctionnaireSlice';
import SuiviDpph from './SuiviDpph';
import MainLayout from '../../layout/MainLayout';
// ==============================|| SAMPLE PAGE ||============================== //

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const documents = Yup.array().min(1, 'Obligatoire');

const form = {
    fullname: '',
    birthDate: '',
    lieunaiss: '',
    gender: '',
    homeadresse: '',
    teleNum: '',
    email: '',
    whatsapp: '',
    linkedin: '',
    numpiece: '',
    ministere: null,
    emploi: null,
    fonction: null,
    direction: '',
    service: '',
    grade: '',
    typehandicap: null,
    sTypehandicap: null,
    typePiece: null,
    typeProfile: null,
    localisation: null,
    matricule: null,
    datePremierePriseService: null,
    dateDepartRetraire: null,
    dateDebutHandicap: null,
    besoins: '',
    accomresonable: '',
    expprofessionel: '',
    servicesoutien: '',
    origine: '',
    autreinfo: '',
    password: '',
    documents: [],
    typeHandiArray: [],
    sTypeHandiArray: []
}
const FicheAgent = ({ ...others }) => {
    const theme = useTheme();
    const { ministers, typeHandicaps } = useSelector(state => state.referenciel);
    const { isFetching } = useSelector(state => state.fonctionnaire);
    const [activeStep, setActiveStep] = useState(0);
    const [failed, setFailed] = useState({});
    const [completed, setCompleted] = useState({});
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [formInit, setFormInit] = useState(form);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState("");

    const { t } = useTranslation('translation');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    let { id } = useParams();

    useEffect(() => {
        doReload()
    }, [])

    const doReload = () => {
        let payload = {
            id: id,
            onSuccess: (data) => {
                const array1 = data.typehandicap ? data.typehandicap.split(',') : [];
                const array2 = data.sTypehandicap ? data.sTypehandicap.split(',') : [];
                setFormInit({ ...data, typeHandiArray: array1, sTypeHandiArray: array2 })
            }
        }
        dispatch(fonctActions.findAgent(payload))
    }

    const normalizeInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(+${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            return `(+${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 9)}-${currentValue.slice(9, 12)}`;
        }
    }

    const normalizeDateInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 2) return currentValue;
            if (cvLength < 5) return `${currentValue.slice(0, 2)}/${currentValue.slice(2)}`;
            return `${currentValue.slice(0, 2)}/${currentValue.slice(2, 4)}/${currentValue.slice(4, 8)}`;
        }
    }

    function delay(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const unsaved = (docs) => {
        return docs.filter(d => d?.file)
    }

    const updateDemande = (values) => {
        let payload = {
            data: {
                ...values,
                typehandicap: values.typeHandiArray.toString(),
                sTypehandicap: values.sTypeHandiArray.toString(),
                documents: []
            },
            onSuccess: (data) => {
                const items = unsaved(values.documents);

                if (items && unsaved(items).length > 0) {
                    items.forEach((doc, index) => {
                        console.log(doc);
                        delay(150).then(() => {
                            const formData = new FormData();
                            formData.append("file", doc.file);
                            formData.append("fonctId", data.id);
                            formData.append("documentType", doc.documentType);
                            let payload = {
                                formData: formData,
                                onSuccess: () => {
                                    if (index == items.length - 1) {
                                        setOpen(true);
                                        doReload()
                                    }
                                },
                            };
                            dispatch(fonctActions.uploadFile(payload));
                        });
                    });
                } else {
                    setOpen(true);
                    doReload()
                }
            },
        };
        dispatch(fonctActions.update(payload));
    }


    const formik = useFormik({
        initialValues: { ...formInit },
        // validationSchema:
        //     Yup.object().shape({
        //         email: Yup.string().email('Email invalid').max(255).required('Champ obligatoire'),
        //         fullname: Yup.string().max(255).required('Champ obligatoire'),
        //         birthDate: Yup.string().max(255).required('Champ obligatoire'),
        //         lieunaiss: Yup.string().max(255).required('Champ obligatoire'),
        //         numpiece: Yup.string().max(255).required('Champ obligatoire'),
        //         direction: Yup.string().max(255).required('Champ obligatoire'),
        //         matricule: Yup.string().max(255).required('Champ obligatoire'),
        //         fonction: Yup.string().max(255).required('Champ obligatoire'),
        //         emploi: Yup.string().max(255).required('Champ obligatoire'),
        //         datePremierePriseService: Yup.string().max(255).required('Champ obligatoire'),
        //         gender: Yup.string().max(255).required('Champ obligatoire'),
        //         teleNum: Yup.string().max(255).required('Champ obligatoire'),
        //         homeadresse: Yup.string().max(255).required('Champ obligatoire'),
        //     }),
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log(values)
            updateDemande(values)
        }

    })

    const { errors, handleSubmit, isSubmitting } = formik;
    const steps = [`${t("register.title1")}`, `${t("register.title2")}`, `${t("register.title3")}`, `${t("register.title4")}`];


    const handleStep = (step) => {
        setActiveStep(step);
    };

    const handleNext = () => {
        navigate(-1);
    };

    const handleBack = () => {
        console.log("back");
        if (activeStep > 0) {
            setActiveStep((prevStep) => prevStep - 1);
        }
    };

    const handleSave = () => {
        if (formik.errors.email) {
            formik.setFieldTouched('email', true);
            setActiveStep(0)
        }
    }

    return (
        <>
            {isFetching ? <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isFetching} >
                <CircularProgress color="inherit" />
            </Backdrop> :
                <MainLayout title={t('pages.fiche')} showReturnButton={true}>
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <Stepper nonLinear activeStep={activeStep} sx={{ mt: 2 }}>
                            {steps.map((label, index) => {
                                const labelProps = {};
                                if (failed[index]) {
                                    labelProps.optional = (
                                        <Typography variant="caption" color="error">
                                            {error}
                                        </Typography>
                                    );
                                    labelProps.error = true;
                                }
                                return (
                                    <Step key={label} completed={completed[index]}>
                                        <StepButton
                                            color="inherit"
                                            onClick={() => handleStep(index)}
                                        >
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        <Box sx={{ my: 1 }}>
                            {activeStep === 0 && <InfoPerso formik={formik} />}
                            {activeStep === 1 && <RenseigAgent formik={formik} />}
                            {activeStep === 2 && <Cv formik={formik} />}
                            {activeStep === 3 && <InfoHandicap formik={formik} />}

                        </Box>
                        <Divider sx={{ "&::before, &::after": { borderColor: "orange", }, my: 1 }}>
                        </Divider>
                        <Box sx={{ my: 1, backgroundColor: "#ffe0b2", p: 2 }}>
                            <SuiviDpph formik={formik} />
                        </Box>


                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ display: 'flex', flexDirection: 'row', py: 2, px: 2 }}>

                            <Button onClick={() => handleNext()} sx={{ mx: 2 }} color='success' variant='outlined'>{t("actions.return")}</Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button startIcon={<Save />} variant='contained' type='submit' color='success' disabled={isSubmitting}>
                                {t("actions.save")}
                            </Button>

                        </Box>
                    </form></MainLayout >}


            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Mis à jour effectué avec succés.
                </Alert>
            </Snackbar>
        </>
    )
};

export default FicheAgent;
