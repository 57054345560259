import { loadRefData } from "../services/dataService";
import { put } from "redux-saga/effects";

const { createModule } = require("saga-slice");

const dataSlice = createModule({
    name:"referenciel",
    initialState: {
        typeHandicaps: [],
        ministers:[],
        typePieces:[],
        isFetching: false,
        error: null,
    },
    reducers: {
        loadRefData: (state) => {
            state.isFetching = true;
        },
        

        fetchSuccess: (state, payload) => {
            state.ministers = payload.body.ministers;
            state.typeHandicaps = payload.body.typeHandicaps;
        },

        finishFetching: (state) => {
            state.isFetching = false;
        },

        fetchError: (state) => {
            state.error = "An error occured";
        },
    },
    sagas: (A) => ({
        *[A.loadRefData]() {
            try {
              const { data } = yield loadRefData();
              yield put(A.finishFetching());
              yield put(A.fetchSuccess(data));
            } catch (e) {
              console.log(e);
              yield put(A.finishFetching());
              yield put(A.fetchError());
            }
          },
    })

})

export default dataSlice;
export const dataActions = dataSlice.actions;
