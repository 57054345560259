import { Alert, Backdrop, Box, Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Skeleton, Snackbar, TextField, Tooltip } from "@mui/material";
import MainLayout from "../../layout/MainLayout";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { dataActions } from "../../sagas/dataSlice";
import { fonctActions } from "../../sagas/fonctionnaireSlice";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector } from "@mui/x-data-grid";
import { useFormik } from "formik";
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomConfirmationDialog from "../../common/CustomConfirmationDialog";
import * as XLSX from 'xlsx'

const search = {
    criteria: {
        id: null,
        fullname: null,
        dateDu: null,
        dateAu: null,
        numpiece: null,
        typeHanicap: null,
        gender: null
    },
    page: 0,
    size: 10,
}

function Item(props) {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{
                bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                p: 1,
                ...sx,
            }}
            {...other}
        />
    );
}

function AgentsPage() {

    const { t } = useTranslation('translation');
    const { typePieces, typeHandicaps, ministers } = useSelector(state => state.referenciel);
    const [form, setForm] = useState(search);
    const [page, setPage] = useState(0)
    const { isLoading, list, total } = useSelector(state => state.fonctionnaire);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [current, setCurrent] = useState(null);
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(fonctActions.search({ ...form, page: page }));
    }, [page])

    const formik = useFormik({
        initialValues: form.criteria,
        enableReinitialize: true,
        onSubmit: (values) => {
            dispatch(fonctActions.search({ ...form, criteria: { ...values }, page: page }));

        }
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const normalizeDateInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 2) return currentValue;
            if (cvLength < 5) return `${currentValue.slice(0, 2)}/${currentValue.slice(2)}`;
            return `${currentValue.slice(0, 2)}/${currentValue.slice(2, 4)}/${currentValue.slice(4, 8)}`;
        }
    }

    const deleteAgent = () => {

        let payload = {
            id: current.id,
            onSuccess: () => {
                setShowConfirmDialog(false)
                setOpen(true)
                setPage(0)
                dispatch(fonctActions.search({ ...form, page: 0 }));
            },
        };

        dispatch(fonctActions.delete(payload));

    }

    const columns = [
        { field: 'matricule', headerAlign: 'center', headerName: t('register.matricule'), flex: 1, },
        { field: 'typeProfile', headerAlign: 'center', headerName: t('register.typeProfile'), flex: 1, },
        { field: 'fullname', headerAlign: 'center', headerName: t('register.fullname'), flex: 1, },
        { field: 'createdDate', headerAlign: 'center', headerName: t('register.enroledOn'), flex: 1, },
        { field: 'birthDate', headerAlign: 'center', headerName: t('register.birthDate'), flex: 1, },
        { field: 'lieunaiss', headerAlign: 'center', headerName: t('register.lieunaiss'), flex: 1, },
        {
            field: "gender",
            headerAlign: "center",
            headerName: t('register.gender'),
            flex: 1,
            renderCell: (params) => {
                let data = params.row.gender === 'H' ? 'Masculin' : 'Feminin';
                return data;
            },
        },
        { field: 'teleNum', headerName: t('register.teleNum'), flex: 1 },
        { field: 'ministere', headerName: t('register.ministere'), flex: 1,
        renderCell: (params) => {
            let data = ministers.find(m => m.code === params.row.ministere)?.description;
            return data;
        }, },
        { field: 'service', headerName: t('register.service'), flex: 1 },
        { field: 'localisation', headerName: t('register.localisation'), flex: 1 },
        { field: 'fonction', headerName: t('register.fonction'), flex: 1 },
        { field: 'grade', headerName: t('register.grade'), flex: 1 },
        { field: 'datePremierePriseService', headerName: t('register.datePremierePriseService'), flex: 1 },
        {
            field: "actions",
            headerName: "Actions",
            flex: 0.75,
            renderCell: (params) => {
                return (

                    <div>
                        <Tooltip title="Supprimer"><IconButton size="small"
                            sx={{
                                width: '24px', height: '24px', backgroundColor: '#091242',
                                color: "#fff", ml: 0.2
                            }}
                            onClick={(event) => {
                                event.ignore = true;
                                setCurrent(params.row);
                                setShowConfirmDialog(true);
                            }}>
                            <DeleteIcon sx={{ width: 16, height: 16 }} />
                        </IconButton></Tooltip>
                    </div>

                );
            },
        },
    ];

    const exportData = () => {
        const payload = {
            onSuccess: (data) => {
                const array = data.map((item) => {
                    return {
                        "Matricule": item.matricule,
                        'Type': item.typeProfile,
                        'Nom et prénoms': item.fullname,
                        'Date d\'enrolement': item.createdDate,
                        'Date de naissance': item.birthDate,
                        'Lieu de naissance': item.lieunaiss,
                        'Sexe': item.gender,
                        'N° téléphone': item.teleNum,
                        'Ministère': ministers.find(m => m.code === item.ministere)?.description,
                        'Service': item.service,
                        'Localisation': item.localisation,
                        'Fonction': item.fonction,
                        'Grade': item.grade,
                        'Date de 1er prise de fonction': item.datePremierePriseService
                    }
                });

                const ws = XLSX.utils.json_to_sheet(array);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.writeFile(wb, "data.xlsx");
            }
        }
        dispatch(fonctActions.list(payload));
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ my: 1 }}>
                <GridToolbarColumnsButton />
                <GridToolbarDensitySelector />

                <Button size="small" startIcon={<DownloadIcon />}
                    onClick={() => { exportData(); }}
                >
                    {t('actions.export')}
                </Button>
            </GridToolbarContainer>
        );
    }

    const handleRowClick = (params, event) => {
        if (!event.ignore && event.ctrlKey) {
            navigate(`/main/agents/${params.row.id}`);
        }
    };

    const handleReset = () => {
        setForm(prevForm => search);
        dispatch(fonctActions.search({ ...form, page: 0 }));
    }

    return (
        <>
            {isLoading ?
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}>

                    <CircularProgress color="inherit" />
                </Backdrop>
                :
                <MainLayout title={t('pages.agents')}>
                    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                        <Grid container spacing={2}
                            sx={{ my: 2, px: 1, py: 1, boxShadow: 2, borderRadius: 1, border: '1px solid', borderColor: '#091242' }}>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <TextField
                                        {...formik.getFieldProps('numpiece')}
                                        label={t('criteria.numpiece')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <TextField
                                        {...formik.getFieldProps('fullname')}
                                        label={t('criteria.fullname')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="type-sexe" >{t('criteria.gender')}</InputLabel>
                                    <Select
                                        labelId="type-sexe"
                                        {...formik.getFieldProps('gender')}
                                        label={t('criteria.gender')}>
                                        <MenuItem value="H">Homme</MenuItem>
                                        <MenuItem value="F">Femme</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="type-handicap" >{t('criteria.typeHanicap')}</InputLabel>
                                    <Select
                                        labelId="type-handicap"
                                        {...formik.getFieldProps('typehandicap')}
                                        label={t('criteria.typeHanicap')}>
                                        {typeHandicaps.map((item) => (<MenuItem key={item.code} value={item.code}>{item.description}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <TextField variant="outlined"
                                        {...formik.getFieldProps('dateDu')}
                                        onChange={({ target }) => {
                                            formik.setFieldValue("dateDu", normalizeDateInput(target.value, formik.values.dateDu))
                                        }}
                                        inputProps={{}}
                                        placeholder='dd/MM/yyyy'
                                        label={t('criteria.dateDu')}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <TextField variant="outlined"
                                        label={t('criteria.dateAu')}
                                        {...formik.getFieldProps('dateAu')}
                                        onChange={({ target }) => {
                                            formik.setFieldValue("dateAu", normalizeDateInput(target.value, formik.values.dateAu))
                                        }}
                                        inputProps={{}}
                                        placeholder='dd/MM/yyyy'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} display="flex"
                                justifyContent="flex-end"
                                alignItems="flex-end">
                                <Button type="submit" variant="contained" sx={{ backgroundColor: "orange" }}> {t('criteria.button')} </Button>
                                <Button onClick={handleReset} variant="contained" sx={{ mx: 1, backgroundColor: "orange" }}> {t('criteria.reset')} </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <Grid container>

                        <Grid item xs={12}>
                            <DataGrid
                                onRowClick={handleRowClick}
                                rows={list}
                                columns={columns}
                                rowCount={total}
                                pagination
                                page={page}
                                paginationMode="server"
                                autoHeight
                                pageSize={10}
                                onPageChange={(newPage) => setPage(newPage)}
                                rowsPerPageOptions={[10]}
                                sx={{
                                    boxShadow: 2, "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#03a85b",
                                        color: "#fff",
                                        fontSize: 12,
                                    }
                                }}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                            />
                        </Grid>
                    </Grid>
                </MainLayout >
            }

            {showConfirmDialog && <CustomConfirmationDialog
                open={showConfirmDialog}
                close={() => setShowConfirmDialog(false)}
                header="Confirmation"
                message={t('messages.delete')}
                cancelText={t('actions.cancel')}
                confirmText={t('actions.confirm')}
                cancelOnClick={() => setShowConfirmDialog(false)}
                confirmOnClick={() => deleteAgent()}
            />}

            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {t('messages.opersuccess')}
                </Alert>
            </Snackbar>
        </>
    )
}

export default AgentsPage;