import { useTranslation } from "react-i18next";
import DocumentBloc from "./DocumentBloc";
import { FormikProvider } from "formik";
import { Grid, Typography } from "@mui/material";

const Cv = ({ formik }) => {
    const { t } = useTranslation('translation');

    const { getFieldProps, setFieldValue, touched, errors, values } = formik;

    return (
        <FormikProvider value={formik}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography component="span" variant="subtitle">
                        {t('register.cv')}
                    </Typography>
                    <DocumentBloc docType="CV" setFieldValue={setFieldValue} values={values} errors={errors} touched={touched} />
                </Grid>
            </Grid>
        </FormikProvider>
    )
}

export default Cv;