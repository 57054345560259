import { login } from "../services/authService";
import { call, put } from "redux-saga/effects";

const { createModule } = require("saga-slice");

const authSlice = createModule({
    name:"user",
    initialState:{
        user:{},
        isLogging: false,
        error:''
    },
    reducers:{
        login: (state) => {
            state.isLogging = true;
        },
        loginSuccess:(state, payload) => {
            state.user = payload;
            sessionStorage.setItem("token",payload.accessToken);
        },
        finishLogging: (state) => {
            state.isLogging = false;
        },

        loginError: (state) => {
            state.error = "An error occured";
        },
    },
    sagas: (A) => ({
        *[A.login]({ payload }) {
            try {
              const { data } = yield login(payload.data);
              yield put(A.finishLogging());
              yield put(A.loginSuccess(data));
              yield call(payload.onSuccess());

            } catch (e) {
              console.log(e);
              yield put(A.finishLogging());
              yield put(A.loginError());
            }
        },
    })

})

export default authSlice;
export const authActions = authSlice.actions;

