import axios from "axios";
import config from "../config";

export const login = (query) => {
    let headers = {
        "Content-type": "application/json",
    };
    return axios.post(`${config.baseURL}/auth/login`, JSON.stringify(query), {
        headers: headers,
    });
};