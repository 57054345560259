import { Close, CloseSharp, Download, FileOpen, Preview, Remove } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useTheme } from "@mui/system";
import { IconClearAll, IconFile, IconFile3d, IconPhoto } from "@tabler/icons";

const DocumentBloc = ({ docType, setFieldValue, values, errors, touched }) => {
    const { t } = useTranslation('translation');
    const [files, setFiles] = useState([]);
    const [items, setItems] = useState([]);
    const [documents, setDocuments] = useState([...values.documents]);
    const inputFileRef = useRef()
    const dispatch = useDispatch();
    const theme = useTheme();

    useEffect(() => {
        if (documents) {
            setItems(filterByDocType(docType));
            setFiles(filterDraftByDocType(docType))
        }
    }, []);

    const handleSelectFile = (event) => {
        const data = event.target.files;
        const filename = data[0].name;
        const size = data[0].size;
        const validFileTypes = [
            'image/jpeg','image/png',
            'application/pdf'
        ];

        if (!validFileTypes.includes(data[0].type)) {
            //alertDispatch('error', t('messages.docMsg1'));
            inputFileRef.current.value = null;
        } else if (size > 3000000) {
            //alertDispatch('error', t('messages.docMsg3'));
            inputFileRef.current.value = null;
        } else {
            setFiles([...files, data[0]]);
            let doc = {
                id: Math.floor(Math.random() * (600 - 99 + 1)) + 99,
                file: data[0],
                documentType: docType,
            }
            inputFileRef.current.value = null;
            handleAddFile(doc);
        }
    };

    const filterByDocType = (code) => {
        return documents.filter(doc => doc.id && doc?.docType == code)
    }

    const filterDraftByDocType = (code) => {
        return documents.filter(doc => doc?.file && doc?.documentType == code).map(doc => doc.file)
    }

    const handleAddFile = (doc) => {
        setFieldValue("documents", [...values.documents, doc])
    }

    const handleRemoveFile = (file) => {

        inputFileRef.current.value = null;
        let updated = null;
        if (file?.id) {
            updated = items.filter(p => p.filename != file.filename);
            setItems(updated);
            setFieldValue("documents", values.documents.filter(doc => doc.id !== file.id))
        } else {
            updated = files.filter(p => p.name != file.name);
            setFiles(updated);
            setFieldValue("documents", values.documents.filter(doc => doc.file.name != file.name))
        }

        if (file?.uuid) {
            handleDeleteFile(file.id)
        }
    }

    const handleDeleteFile = (id) => {
        console.log('handle delete quittance paiement doc : ', id);
        // let payload = {
        //     documentId: id,
        //     onSuccess: () => {
        //         doReload();
        //     },

        // };
        // console.log('payload : ', payload);
        // dispatch(demandeActions.deleteFile(payload));
    }

    const selectFile = (event) => {
        let inputSelect = inputFileRef.current;
        inputSelect.click();
        //setFiles(event.target.files);
    };


    return <>
        <Grid container sx={{ backgroundColor: "none" }}>
            <Grid
                item
                xs={12} sm={9} md={8}
                sx={{ color: "#fff", textAlign: 'center' }}>
                <label htmlFor="btn-upload">
                    <input
                        accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        name="btn-upload"
                        style={{ display: 'none' }}
                        ref={inputFileRef}
                        type="file"
                        onChange={handleSelectFile} />

                    <IconButton
                        className="btn-upload"
                        color="primary"
                        variant="contained"
                        component="span"
                        onClick={selectFile}
                    >
                        <IconPhoto />
                    </IconButton>
                    <input
                        mx={2}
                        name="btn-upload"
                        disabled={true}
                        type="text"
                        value={files && files.length > 0 ? files[0].name : ""}
                    />
                </label>

            </Grid>
            <Grid container>
                {
                    files && Array.from(files).map((file, index) => {
                        return (

                            <Grid item xs={12}>
                                <Card
                                    
                                >
                                    <CardContent>
                                        <Grid container direction="column">
                                            <Grid item xs={12}>
                                                <Stack direction="row" spacing={2}>
                                                    <IconFile stroke={1.5} size="1.3rem" />
                                                    <Typography variant="subtitle1">{file.name}</Typography>
                                                    <IconButton disabled={false} onClick={() => handleRemoveFile(file)} >
                                                            <CloseSharp />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() => {
                                                                const url = window.URL.createObjectURL(file);
                                                                if (file?.type == "application/pdf") {
                                                                    window.open(url, '_blank').focus();
                                                                } else {
                                                                    const link = document.createElement("a");
                                                                    link.href = url;
                                                                    link.setAttribute(
                                                                        "download",
                                                                        `${file.name}`
                                                                    );
                                                                    document.body.appendChild(link);
                                                                    link.click();

                                                                }
                                                            }}>
                                                            <Preview />
                                                        </IconButton>
                                                    
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    })
                }</Grid>
                {
                    items && Array.from(items).map((file, index) => {
                        return (
                            <Box sx={{
                                mb: 0,
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
                                    <Typography
                                        component="div"
                                        sx={{ display: 'inline-grid', alignItems: 'center' }}>

                                        <Typography icon={<FileOpen />} size="small">
                                            {file.name}
                                        </Typography>
                                    </Typography>
                                </Box>
                                <Typography component="div" sx={{ textAlign: 'right' }}>
                                    <IconButton
                                        disabled={values.status && values.status !== 'NOUVEAU'}
                                        onClick={() => handleRemoveFile(file)}>
                                        <Close />
                                    </IconButton>
                                </Typography>
                                <Typography component="div" sx={{ textAlign: 'right' }}>
                                    <IconButton
                                        onClick={() => {
                                            console.log("item", file)
                                            const pdfWindow = window.open();
                                                        pdfWindow.document.write(
                                                            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                                                            encodeURI(file.data) + "'></iframe>"
                                                        );

                                        }}>
                                        <Preview />
                                    </IconButton>
                                </Typography>
                            </Box>)
                    })
                }
            </Grid>
        </>
}

        export default DocumentBloc;