import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';


const CustomConfirmationDialog = (props) => {

    return <Dialog

        open={props.open}
        onClose={props.close}>
        <DialogTitle >
            {props.header}
        </DialogTitle>
        <DialogContent >
            <DialogContentText id="alert-dialog-description">
                {props.message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button size='small' color='error' onClick={props.cancelOnClick} variant="contained">
                {props.cancelText}
            </Button>
            <Button size='small' color='success' autoFocus onClick={props.confirmOnClick} variant="contained">
                {props?.confirmText ?? props?.saveText}
            </Button>
            {props?.sendText && <Button size='small' autoFocus onClick={props.sendOnClick} color='success' variant="contained">
                {props.sendText}
            </Button>}

        </DialogActions>
    </Dialog>
}
export default CustomConfirmationDialog;