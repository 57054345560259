import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Snackbar,
    Step,
    StepButton,
    StepLabel,
    Stepper,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

const SuiviDpph = ({ formik }) => {

    const { getFieldProps, values, errors, touched } = formik;
    const { t } = useTranslation('translation');
    const theme = useTheme();
    return (
        <Grid container spacing={2}>
            <Grid xs={12}>
                <Typography variant="h5" gutterBottom>
                    {t('register.title5')}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <FormControl fullWidth error={Boolean(touched.besoins && errors.besoins)} sx={{ ...theme.typography.customInput }}>
                    <InputLabel htmlFor="outlined-adornment-besoins-register">{t('register.besoins')}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-besoins-register"
                        {...getFieldProps('besoins')}
                        inputProps={{}}
                    />
                    {touched.besoins && errors.besoins && (
                        <FormHelperText error id="standard-weight-helper-text--register">
                            {errors.besoins}
                        </FormHelperText>
                    )}
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
                <FormControl fullWidth error={Boolean(touched.accomresonable && errors.accomresonable)} sx={{ ...theme.typography.customInput }}>
                    <InputLabel htmlFor="outlined-adornment-accomresonable-register">{t('register.accomresonable')}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-accomresonable-register"
                        {...getFieldProps('accomresonable')}
                        inputProps={{}}
                    />
                    {touched.accomresonable && errors.accomresonable && (
                        <FormHelperText error id="standard-weight-helper-text--register">
                            {errors.accomresonable}
                        </FormHelperText>
                    )}
                </FormControl>
            </Grid>
            <Grid xs={12}>
                <Typography variant="h5" gutterBottom>
                    {t('register.title7')}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                    <InputLabel htmlFor="outlined-adornment-servicesoutien-register">{t('register.servicesoutien')}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-servicesoutien-register"
                        {...getFieldProps('servicesoutien')}
                        inputProps={{}}
                    />
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default SuiviDpph;