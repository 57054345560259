import { useSelector } from "react-redux";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Snackbar,
    Step,
    StepButton,
    StepLabel,
    Stepper,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import { FormikProvider } from "formik";
import DocumentBloc from "./DocumentBloc";

const RenseigAgent = ({ formik }) => {
    const { t } = useTranslation('translation');
    const { getFieldProps, setFieldValue, touched, errors, values } = formik;
    const { ministers } = useSelector(state => state.referenciel);
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const normalizeDateInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 2) return currentValue;
            if (cvLength < 5) return `${currentValue.slice(0, 2)}/${currentValue.slice(2)}`;
            return `${currentValue.slice(0, 2)}/${currentValue.slice(2, 4)}/${currentValue.slice(4, 8)}`;
        }
    }

    return (
        <FormikProvider value={formik}>
            <Grid container spacing={matchDownSM ? 0 : 2}>

                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel id="demo-simple-select-label">{t('register.ministere')}</InputLabel>
                        <Select
                            // MenuProps={{ classes: { paper: classes.menuPaper } }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            {...getFieldProps('ministere')}
                            label={t('register.ministere')}
                            disabled>
                            {ministers.map((item) => (<MenuItem key={item.code} value={item.code}>{item.description}</MenuItem>))}
                        </Select>
                        
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-direction-register">{t('register.direction')}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-direction-register"
                            {...getFieldProps('direction')}
                            inputProps={{}}
                            disabled
                        />
                        
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-service-register">{t('register.service')}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-service-register"
                            {...getFieldProps('service')}
                            inputProps={{}}
                            disabled
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-localisation-register">{t('register.localisation')}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-localisation-register"
                            {...getFieldProps('localisation')}
                            inputProps={{}}
                            disabled
                        />
                        
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-matricule-register">{t('register.matricule')}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-matricule-register"
                            {...getFieldProps('matricule')}
                            inputProps={{}}
                            disabled
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth  sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-datePremierePriseService-register">{t('register.datePremierePriseService')}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-datePremierePriseService"
                            {...getFieldProps('datePremierePriseService')}
                            onChange={({ target }) => {
                                setFieldValue("datePremierePriseService", normalizeDateInput(target.value, values.datePremierePriseService))
                            }}
                            label={t('register.datePremierePriseService')}
                            placeholder='dd/MM/yyyy'
                            inputProps={{}}
                            disabled
                        /> 
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="span" variant="subtitle">
                        {t('register.arret')}
                    </Typography>
                    <DocumentBloc docType="ARRET" setFieldValue={setFieldValue} values={values} errors={errors} touched={touched} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-emploi-register">{t('register.emploi')}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-emploi-register"
                            {...getFieldProps('emploi')}
                            inputProps={{}}
                            disabled
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth  sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-fonction-register">{t('register.fonction')}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-fonction-register"
                            {...getFieldProps('fonction')}
                            inputProps={{}}
                            disabled
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-grade-register">{t('register.grade')}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-grade-register"
                            {...getFieldProps('grade')}
                            inputProps={{}}
                            disabled
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-dateDepartRetraire-register">{t('register.dateDepartRetraire')}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-dateDepartRetraire"
                            {...getFieldProps('dateDepartRetraire')}
                            onChange={({ target }) => {
                                setFieldValue("dateDepartRetraire", normalizeDateInput(target.value, values.dateDepartRetraire))
                            }}
                            label={t('register.dateDepartRetraire')}
                            placeholder='dd/MM/yyyy'
                            inputProps={{}}
                            disabled
                        /> 
                    </FormControl>
                </Grid>
            </Grid>
        </FormikProvider>
    )

}

export default RenseigAgent;