import { Box, Button, Checkbox, Container, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from 'yup';
import { authActions } from "../../sagas/authSlice";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from '../../assets/images/logo.jpeg';

const login = yup.object().shape({
    username: yup.string().trim().required("Champ obligatoire"),
    password: yup.string().trim().required("Champ obligatoire"),
});

function LoginForm() {

    const { t } = useTranslation('translation')

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: { username: '', password: '' },
        validationSchema: login,
        onSubmit: (values) => {
            let payload = {
                data: values,
                onSuccess: () => {
                    navigate('/main/agents');
                }
            }
            dispatch(authActions.login(payload));
        }
    });
    return (
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        padding:2,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center", border: '1px solid', borderRadius: 5, backgroundColor: '#fff3e0'
                    }}
                >
                    <img src={logo} alt="Berry" width="100" />
                    <Box sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            fullWidth
                            label={t('login.username')}
                            {...formik.getFieldProps('username')}
                            InputLabelProps={{ shrink: true }}  
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label={t('login.password')}
                            {...formik.getFieldProps('password')}
                            InputLabelProps={{ shrink: true }}  
                            type="password"
                            autoComplete="current-password"
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="success"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {t('login.button')}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    {t('login.forgotpwd')}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </form>
    )
}

export default LoginForm;