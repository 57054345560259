import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Snackbar,
    Step,
    StepButton,
    StepLabel,
    Stepper,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import { useEffect } from 'react';

const { FormikProvider } = require("formik")

const InfoPerso = ({ formik }) => {
    const { t } = useTranslation('translation');
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const {  handleBlur, handleChange, getFieldProps, setFieldValue, touched, errors, values } = formik;
    const { typePieces } = useSelector(state => state.referenciel);
    const [showPassword, setShowPassword] = useState(false);
    const [strength, setStrength] = useState(0);
    const [level, setLevel] = useState();

    useEffect(() => {

    },[values])

    const normalizeInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 4) return currentValue;
            if (cvLength < 7) return `(+${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
            return `(+${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 9)}-${currentValue.slice(9, 13)}`;
        }
    }



    const normalizeDateInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 2) return currentValue;
            if (cvLength < 5) return `${currentValue.slice(0, 2)}/${currentValue.slice(2)}`;
            return `${currentValue.slice(0, 2)}/${currentValue.slice(2, 4)}/${currentValue.slice(4, 8)}`;
        }
    }

    return (
        <FormikProvider value={formik}>
            <Grid container spacing={matchDownSM ? 0 : 2}>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-fullname-register">{t('register.fullname')}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-fullname-login"
                            {...getFieldProps('fullname')}
                            label={t('register.fullname')}
                            inputProps={{}}
                            disabled
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-birthDate-register">{t('register.birthDate')}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-birthDate"
                            {...getFieldProps('birthDate')}
                            onChange={({ target }) => {
                                setFieldValue("birthDate", normalizeDateInput(target.value, values.birthDate))
                            }}
                            label={t('register.birthDate')}
                            placeholder='dd/MM/yyyy'
                            inputProps={{}}
                            disabled
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-lieunaiss-register">{t('register.lieunaiss')}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-lieunaiss-login"
                            {...getFieldProps('lieunaiss')}
                            label={t('register.lieunaiss')}
                            inputProps={{}}
                            disabled
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel id="demo-simple-select-label">{t('register.gender')}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            {...getFieldProps('gender')}
                            label={t('register.gender')}
                            disabled
                        >
                            <MenuItem value="H">Homme</MenuItem>
                            <MenuItem value="F">Femme</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel id="demo-simple-select-label1">{t('register.typePiece')}</InputLabel>
                        <Select
                            // MenuProps={{ classes: { paper: classes.menuPaper } }}
                            labelId="demo-simple-select-label1"
                            id="demo-simple-select"
                            {...getFieldProps('typePiece')}
                            label={t('register.typePiece')} disabled>
                            {typePieces.map((item) => (<MenuItem key={item.code} value={item.code}>{item.description}</MenuItem>))}
                            
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-numpiece-register">{t('register.numpiece')}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-numpiece-register"
                            {...getFieldProps('numpiece')}
                            label={t('register.numpiece')}
                            inputProps={{}}
                            disabled
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel id="demo-simple-select-label">{t('register.teleNum')}</InputLabel>
                        <OutlinedInput
                            label={`${t("teleNum")}`}
                            {...getFieldProps("teleNum")}
                            placeholder="(+225) xxx-xxx-xxxx"
                            onChange={({ target }) => {
                                setFieldValue("teleNum", normalizeInput(target.value, values.teleNum))
                            }}
                            disabled
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel id="demo-simple-select-label">{t('register.whatsapp')}</InputLabel>
                        <OutlinedInput
                            label={`${t("whatsapp")}`}
                            {...getFieldProps("whatsapp")}
                            placeholder="(+225) xxx-xxx-xxx"
                            onChange={({ target }) => {
                                setFieldValue("whatsapp", normalizeInput(target.value, values.whatsapp))
                            }}
                            disabled
                        />
                        
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-linkedin-register">{t('register.linkedin')}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-linkedin-register"
                            {...getFieldProps("linkedin")}
                            inputProps={{}}
                            disabled
                        />
                        
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-email-register">{t('register.email')}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-email-register"
                            type="email"
                            {...getFieldProps("email")}
                            inputProps={{}}
                            disabled
                        />
                        
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-homeadresse-register">{t('register.homeadresse')}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-homeadresse-register"
                            {...getFieldProps('homeadresse')}
                            inputProps={{}}
                            disabled
                        />
                        
                    </FormControl>
                </Grid>
              
            </Grid>
        </FormikProvider>
    )

}

export default InfoPerso;