import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Snackbar,
    Step,
    StepButton,
    StepLabel,
    Stepper,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import { FormikProvider } from "formik";
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import DocumentBloc from './DocumentBloc';
import { useEffect } from 'react';

const InfoHandicap = ({ formik }) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [tHstate, setTHState] = useState({
        physique: false,
        sensoriel: false,
        intellectuel: false,
    });

    const [origineH, setOrigineH] = useState({
        acquis: false,
        inne: false,
        acquisinne: false,
    });

    const [sType, setStype] = useState({
        ptaille: false,
        moteur: false,
        albinos: false,
        nvoyant: false,
        mvoyant: false,
        sourd: false,
        matendant: false,
        begue: false,
        psychique: false,
        imc: false,
        trisomique: false,
        dysharomie: false,
        autiste: false
    });

    const { t } = useTranslation('translation');
    const { getFieldProps, setFieldValue, touched, errors, values } = formik;
    const { typeHandicaps, sTypeHandicaps } = useSelector(state => state.referenciel);

    useEffect(() => {
        //const array1 = values.typeHandiArray.split(',');
        const obj = values.typeHandiArray.reduce((accumulator, value) => {
            return { ...accumulator, [value]: true };
        }, {});
        let newState = { ...tHstate, ...obj, };
        setTHState(newState);

        const obj2 = values.sTypeHandiArray.reduce((accumulator, value) => {
            return { ...accumulator, [value]: true };
        }, {});
        newState = { ...sType, ...obj2, };
        setStype(newState);
        console.log(newState);

        if(values.origine === 'acquis'){
            setOrigineH({
                acquis: true,
                inne: false,
                acquisinne: false,
            });
        }else if(values.origine === 'inne'){
            setOrigineH({
                acquis: false,
                inne: true,
                acquisinne: false,
            });
        }else if(values.origine === 'acquisinne'){
            setOrigineH({
                acquis: false,
                inne: false,
                acquisinne: true,
            });
        }

    }, [])

    const normalizeDateInput = (value, previousValue) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (!previousValue || value.length > previousValue.length) {
            if (cvLength < 2) return currentValue;
            if (cvLength < 5) return `${currentValue.slice(0, 2)}/${currentValue.slice(2)}`;
            return `${currentValue.slice(0, 2)}/${currentValue.slice(2, 4)}/${currentValue.slice(4, 8)}`;
        }
    }

    const handleTHChange = (event) => {
        setTHState({
            ...tHstate,
            [event.target.name]: event.target.checked,
        });

        if (event.target.checked) {
            setFieldValue('typeHandiArray', [...values.typeHandiArray, event.target.name]);
        } else {
            let filtered = values.sTypehandicapArray?.filter(st => st !== event.target.name);
            setFieldValue('typeHandiArray', filtered);
        }
    }

    const handleOrigChange = (event) => {
        if (event.target.name === 'acquis') {
            setOrigineH({
                acquis: true,
                inne: false,
                acquisinne: false,
            });
        }else if (event.target.name === 'inne') {
            setOrigineH({
                acquis: false,
                inne: true,
                acquisinne: false,
            });
        } else if (event.target.name === 'acquisinne') {
            setOrigineH({
                acquis: false,
                inne: false,
                acquisinne: true,
            });
        }

        setFieldValue('origine', event.target.name)
    }

    const handleSTypeChange = (event) => {
        setStype({
            ...sType,
            [event.target.name]: event.target.checked,
        });

        if (event.target.checked) {
            setFieldValue('sTypeHandiArray', [...values.sTypeHandiArray, event.target.name]);
        } else {
            let filtered = values.sTypeHandiArray.filter(st => st !== event.target.name);
            setFieldValue('sTypeHandiArray', filtered);
        }
    }
    const { acquis, inne, acquisinne } = origineH;
    const { physique, sensoriel, intellectuel } = tHstate;
    const { ptaille, moteur, albinos, nvoyant, mvoyant, sourd, matendant, begue, psychique, imc, trisomique, dysharomie, autiste } = sType;
    return (
        <FormikProvider value={formik}>
            <Grid container spacing={matchDownSM ? 0 : 2}>
                <Grid item xs={12} sm={4}>
                    <FormControl sx={{ m: 1 }} component="fieldset"  >
                        <FormLabel component="legend">{t('register.typehandicap')}</FormLabel>
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={physique} onChange={handleTHChange} name="physique" />
                                }
                                label="Physique"
                                disabled
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox checked={sensoriel} onChange={handleTHChange} name="sensoriel" />
                                }
                                label="Sensoriel (cécité, sourd)"
                                disabled
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox checked={intellectuel} onChange={handleTHChange} name="intellectuel" />
                                }
                                label="Intellectuel"
                                disabled
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
                        <FormLabel component="legend">{t('register.stypehandicap')}</FormLabel>
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={ptaille} onChange={handleSTypeChange} name="ptaille" />
                                }
                                label="Petite taille"
                                disabled
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox checked={moteur} onChange={handleSTypeChange} name="moteur" />
                                }
                                label="Moteur"
                                disabled
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox checked={albinos} onChange={handleSTypeChange} name="albinos" />
                                }
                                label="Albinos"
                                disabled
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={nvoyant} onChange={handleSTypeChange} name="nvoyant" />
                                }
                                label="Non voyant"
                                disabled
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox checked={mvoyant} onChange={handleSTypeChange} name="mvoyant" />
                                }
                                label="Mal voyant"
                                disabled
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox checked={sourd} onChange={handleSTypeChange} name="sourd" />
                                }
                                label="Sourd"
                                disabled
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox checked={matendant} onChange={handleSTypeChange} name="matendant" />
                                }
                                label="Mal entendant"
                                disabled
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox checked={begue} onChange={handleSTypeChange} name="begue" />
                                }
                                label="Bègue"
                                disabled
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={psychique} onChange={handleSTypeChange} name="psychique" />
                                }
                                label="Psychique"
                                disabled
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox checked={imc} onChange={handleSTypeChange} name="imc" />
                                }
                                label="IMC"
                                disabled
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox checked={autiste} onChange={handleSTypeChange} name="autiste" />
                                }
                                label="Autiste"
                                disabled
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox checked={trisomique} onChange={handleSTypeChange} name="trisomique" />
                                }
                                label="Trisomique"
                                disabled
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox checked={dysharomie} onChange={handleSTypeChange} name="dysharomie" />
                                }
                                label="Dysharomie cognitive"
                                disabled
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>


                <Grid item xs={12} sm={4}>
                    <FormControl sx={{ m: 1 }} component="fieldset"  >
                        <FormLabel component="legend">{t('register.originehandic')}</FormLabel>
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={acquis} onChange={handleOrigChange} name="acquis" />
                                }
                                label="Acquis"
                                disabled
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox checked={inne} onChange={handleOrigChange} name="inne" />
                                }
                                label="Inné"
                                disabled
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox checked={acquisinne} onChange={handleOrigChange} name="acquisinne" />
                                }
                                label="Acquis et Inné"
                                disabled
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography component="span" variant="subtitle">
                        {t('register.certificat')}
                    </Typography>
                    <DocumentBloc docType="CERTMEDIC" setFieldValue={setFieldValue} values={values} errors={errors} touched={touched} />
                </Grid>
            </Grid>
        </FormikProvider>
    )
}

export default InfoHandicap;