import axios from "axios";
import instance from "./AxiosInterceptor";
import config from "../config";

export const search = (query) => {
    return instance.post(`/fonctionnaire/search`, JSON.stringify(query));
};


export const create = (query) => {
    return instance.post(`/fonctionnaire/create`, JSON.stringify(query));
};

export const update = (query) => {
    return instance.put(`/fonctionnaire/update`, JSON.stringify(query));
};

export const findById = (id) => {
    return instance.get(`/fonctionnaire/${id}`);
};

export const list = () => {
    return instance.get(`/fonctionnaire/list`);
};

export const findCurrent = () => {
    return instance.get(`/fonctionnaire/current`);
};

export const uploadFile = (formData) => {
    let headers = { "Content-type": "multipart/form-data", "Authorization": "Bearer " + sessionStorage.getItem("token") }
    return axios.post(`${config.baseURL}/fonctionnaire/uploadFile`, formData, { headers: headers });
};
export const deleteAgent = (id) => {
    return instance.delete(`/fonctionnaire/delete/${id}`);
};
  
export const downloadFile = (guid) => {
    return axios.get(`${config.baseURL}/fonctionnaire/downloadFile?guid=${guid}`,
        {
            headers: {
                "Content-type": "blob", "Authorization": "Bearer " + sessionStorage.getItem("token")
            }
        })
};

