import logo from './logo.svg';
import './App.css';
import LoginForm from './pages/login';
import MainLayout from './layout/MainLayout';
import { Route, Routes } from 'react-router-dom';
import AgentsPage from './pages/agent-list';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dataActions } from './sagas/dataSlice';
import { Backdrop, CircularProgress } from '@mui/material';
import FicheAgent from './pages/fiche-agent';

function App() {

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dataActions.loadRefData());
  }, [])


  return (
    <>
      {isLoading && <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}>

        <CircularProgress color="inherit" />
      </Backdrop>}
      <Routes>
        <Route path='/' element={<LoginForm />} />
        <Route path='/main/agents' element={<AgentsPage />} />
        <Route path='/main/agents/:id' element={<FicheAgent />} />
      </Routes>
    </>
  );
}

export default App;
