import createSagaMiddleware from "@redux-saga/core";
import { rootReducer, rootSaga } from "saga-slice";
import { configureStore } from "@reduxjs/toolkit";
import fonctionnaireSlice from "./sagas/fonctionnaireSlice";
import dataSlice from "./sagas/dataSlice";
import authSlice from "./sagas/authSlice";


const modules = [fonctionnaireSlice,dataSlice, authSlice];

const sagaMiddleware = createSagaMiddleware();

const appReducer = rootReducer(modules);

const store = configureStore({
    reducer:appReducer,
    middleware: [sagaMiddleware]
});

sagaMiddleware.run(rootSaga(modules));

export default store;
