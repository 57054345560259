import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  fr: {
    translation: {
      login:{
        username:"Identifiant",
        password:"Mot de passe",
        title:"S'identifier",
        button:"Connexion",
        register:"Vous n'avez pas de compte ?",
        forgotpwd:"Mot de passe oublié ?",
      },
      pages: {
        agents:'Agents',
        fiche:'Fiche Agent'
      },
      actions:{
        export:"Exporter",
        return: "Retour",
        save:"Enregistrer",
        cancel:"Annuler",
        confirm:"Confirmer",
      },
      criteria: {
        fullname: "Nom complet de l'agent",
        dateDu: "Date d'enrolement Du",
        dateAu: "Date d'enrolement Au",
        numpiece:"N° Pièce",
        typeHanicap: "Type de handicap",
        gender: "Sexe",
        button:"Rechercher",
        reset:"Réinitialiser",
      },
      register:{
        typeProfile:"Type",
        emploi:"Emploi",
        certificat:"Liste des certificats medicaux (pièces jointes)",
        originehandic: "Origine du handicap",
        stypehandicap:"Sous-type de handicap",
        enroledOn:"Date d'enrollement",
        lieunaiss:"Lieu de naissance",
        title: "S'inscrire",
        title1:"1. Informations personnelles :",
        title2:"2. Numéro d'identification de l'agent :",
        title3:"3. Profession et affectation :",
        title4:"4. Informations sur le handicap :",
        title5:"* Besoins et accommodations raisonnables :",
        title6:"6. Antécédents de formation et d'expérience : (Date, description et pièces jointes)",
        title7:"* Services de soutien requis: ",
        title8:"8. Autres informations pertinentes :",
        title9:"9. Photographie récente (Photo à joindre) :",
        fullname: "Nom complet de l'agent",
        birthDate: "Date de naissance",
        gender: "Sexe",
        direction:"Direction",
        homeadresse: "Adresse personnelle",
        teleNum: "Numéro de téléphone",
        email:"Adresse e-mail",
        whatsapp:'WhatsApp',
        linkedin:'LinkedIn',
        matricule:"Matricule",
        datePremierePriseService:"Date de première prise de service",
        cv:"CV",
        fonction:"Fonction",
        dateDepartRetraire:"Date de depart à la retraite",
        arret:"Arrêté de nomination(Pièce à joindre)",
        numident:"Numéro d'identification de l'employé au sein de l'administration publique",
        numpiece:"N° Pièce",
        typePiece:"Type pièce",
        ministere:'Ministère',
        posteocc:'Poste occupé dans la fonction publique',
        service:"Service ou département au sein duquel l'agent travaille",
        grade:'Grade',
        typehandicap:'Type de handicap',
        dateDebutHandicap:'Date début du handicap',
        besoins:"Liste des besoins spécifiques de l'agent en lien avec son handicap",
        accomresonable:"Accommodations raisonnables nécessaires pour faciliter la pleine participation de l'agent au travail",
        expprofessionel:"Expérience professionnelle antérieure pertinente.",
        servicesoutien:"Services d'assistance personnelle nécessaires",
        autreinfo:"Toute autre information pertinente concernant le handicap de l'agent ou ses besoins spécifiques",
        dejacompte:"Vous avez déjà un compte?",
        diplome:"Diplômes, certifications, et compétences professionnelles de l'agent.",
        photo:"Une photographie récente de l'agent, généralement utilisée à des fins d'identification.",
        button:"Enregistrer",
      },
      messages:{
        delete: "Etes-vous sur de vouloir supprimer l'agent",
        opersuccess: "Opération effectué avec succès."
      }
    }
  },
  en: {
    translation: {
      
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
